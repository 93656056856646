import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilStar,
} from '@coreui/icons'
import {
  ProfileOutlined,
  DashboardOutlined,
  BellOutlined,
  GlobalOutlined,
  MessageOutlined,
  LockOutlined,
  DollarOutlined,
  TeamOutlined,
  ScheduleOutlined,
  MailOutlined,
  PictureOutlined,
  BarChartOutlined,
  HddOutlined,
  SafetyCertificateOutlined
} from '@ant-design/icons';
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const menuItems = [
  // {
  //   component: CNavItem,
  //   name: 'دشبورد',
  //   to: '/dashboard',
  //   icon: <DashboardOutlined style={{ fontSize: 25 }} twoToneColor="#eb2f96" />
  //   // badge: {
  //   //   color: 'info',
  //   //   text: 'NEW',
  //   // },
  // },
  {
    component: CNavGroup,
    name: 'گزارشات',
    to: '/report',
    icon: <BarChartOutlined style={{ fontSize: 25 }} twoToneColor="#eb2f96" />,
    permission: 'viewreport',
    items: [
      {
        component: CNavItem,
        name: 'گزارش حضور و سرپرستی',
        to: '/report',
      },
      {
        component: CNavItem,
        name: 'گزارش مالی کارگروه ها',
        to: '/workinggroupfinancialreport'
      },
    ]
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  // {
  //   component: CNavTitle,
  //   name: 'پروفایل',
  //   icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'پروفایل',
    to: '/profile',
    icon: <ProfileOutlined style={{ fontSize: 25 }} twoToneColor="#eb2f96" />
  },
  {
    component: CNavItem,
    name: 'اعلام برنامه',
    to: '/programform',
    icon: <BellOutlined style={{ fontSize: 25 }} twoToneColor="#eb2f96" />,
    permission: 'createprogram'
  },
  // {
  //   component: CNavItem,
  //   name: 'Colors',
  //   to: '/theme/colors',
  //   icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  // // },
  // {
  //   component: CNavItem,
  //   name: 'Typography',
  //   to: '/theme/typography',
  //   icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'محصولات',
  //   to: '/products',
  //   icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavTitle,
  //   name: 'Components',
  // },
  {
    component: CNavGroup,
    name: 'برنامه ها',
    to: '/base',
    icon: <ScheduleOutlined style={{ fontSize: 25 }} twoToneColor="#eb2f96" />,
    items: [
      {
        component: CNavItem,
        name: 'برنامه هایی که سرپرست بودم',
        to: '/myprograms',
      },
      {
        component: CNavItem,
        name: 'برنامه هایی که شرکت کردم',
        to: '/myjoinprograms',
      },
      {
        component: CNavItem,
        name: 'کارگروه آموزش',
        to: '/education',
      },
      {
        component: CNavItem,
        name: 'کارگروه کوهپیمایی',
        to: '/mountaineering',
      },
      {
        component: CNavItem,
        name: 'کارگروه سنگنوردی',
        to: '/rockclimbing',
      },
      {
        component: CNavItem,
        name: 'کارگروه یخنوردی',
        to: '/iceclimbing',
      },
      {
        component: CNavItem,
        name: 'کارگروه غارنوردی',
        to: '/caving',
      },
      {
        component: CNavItem,
        name: 'کارگروه دوچرخه سواری',
        to: '/bicycling',
      },
      {
        component: CNavItem,
        name: 'کارگروه دره نوردی',
        to: '/canyoneering',
      },
      {
        component: CNavItem,
        name: 'کارگروه اسکای رانینگ',
        to: '/skyrunning',
      },
      // {
      //   component: CNavItem,
      //   name: 'Breadcrumb',
      //   to: '/base/breadcrumbs',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Cards',
      //   to: '/base/cards',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Carousel',
      //   to: '/base/carousels',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Collapse',
      //   to: '/base/collapses',
      // },
      // {
      //   component: CNavItem,
      //   name: 'List group',
      //   to: '/base/list-groups',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Navs & Tabs',
      //   to: '/base/navs',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Pagination',
      //   to: '/base/paginations',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Placeholders',
      //   to: '/base/placeholders',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Popovers',
      //   to: '/base/popovers',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Progress',
      //   to: '/base/progress',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Spinners',
      //   to: '/base/spinners',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Tables',
      //   to: '/base/tables',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Tooltips',
      //   to: '/base/tooltips',
      // },
    ],
  },
  {
    component: CNavGroup,
    name: 'برنامه های اعضای آزمایشی',
    permission: 'viewtrialprogram',
    to: '/base',
    icon: <PictureOutlined style={{ fontSize: 25 }} twoToneColor="#eb2f96" />,
    items: [
      {
        component: CNavItem,
        name: 'سال 1403(پرستو ابریشمی)',
        to: '/trial/1403',
      },
      {
        component: CNavItem,
        name: 'سال 1402',
        to: '/trial/1402',
      },
      {
        component: CNavItem,
        name: 'سال 1401',
        to: '/trial/1401',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'اعضاء',
    to: '/base',
    icon: <TeamOutlined style={{ fontSize: 25 }} twoToneColor="#eb2f96" />,
    permission: ['viewmembers', 'viewtrialmembers', 'viewguestmembers'],
    items: [
      {
        component: CNavItem,
        name: 'ایجاد عضو جدید',
        to: '/members/create',
        permission: 'createmember'
      },
      {
        component: CNavItem,
        name: 'کل اعضاء',
        to: '/members/total',
        permission: 'viewmembers'
      },
      {
        component: CNavItem,
        name: 'آزمایشی',
        to: '/members/trials',
        permission: 'viewtrialmembers'
      },
      {
        component: CNavItem,
        name: 'مهمان',
        to: '/members/guest',
        permission: 'viewguestmembers'
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'مالی',
    to: '/base',
    icon: <DollarOutlined style={{ fontSize: 25 }} twoToneColor="#eb2f96" />,
    items: [
      {
        component: CNavItem,
        name: 'قبض های من',
        to: '/financial/mybills',
      },
      // {
      //   component: CNavItem,
      //   name: 'تراکنش های من',
      //   to: '/financial/mypayments',
      //   permission: 'viewmypayments'
      // },
      {
        component: CNavItem,
        name: 'قبض ها',
        to: '/financial/billlist',
        permission: 'viewbills'
      },
      // {
      //   component: CNavItem,
      //   name: 'پرداخت ها',
      //   to: '/financial/payments',
      //   permission: 'viewpayments'
      // },
      {
        component: CNavItem,
        name: 'کیف پول اعضاء',
        to: '/financial/memberswallet',
        permission: 'viewwallets'
      },
      {
        component: CNavItem,
        name: 'کیف پول من',
        to: '/financial/mywallet'
      },
      {
        component: CNavItem,
        name: 'تعرفه دستمزد سرپرستان',
        to: '/financial/programsettings',
        permission: 'viewleaderpolicies'
      },
      {
        component: CNavItem,
        name: 'تعرفه کنسل برنامه',
        to: '/financial/programcancelpolicies',
        permission: 'viewcancelpolicies'
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'انبار',
    to: '/base',
    icon: <HddOutlined style={{ fontSize: 25 }} twoToneColor="#eb2f96" />,
    permission: ['viewstore', 'rentfromstore'],
    items: [
      {
        component: CNavItem,
        name: 'موجودی انبار',
        to: '/store/storelist',
        permission: 'viewstore'
      },
      {
        component: CNavItem,
        name: 'اجاره وسایل',
        to: '/store/rentallist',
        permission: 'rentfromstore'
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'سایت',
    to: '/base',
    permission: 'createblog',
    icon: <GlobalOutlined style={{ fontSize: 25 }} twoToneColor="#eb2f96" />,
    items: [
      {
        component: CNavItem,
        name: 'اخبار',
        to: '/site/blog/bloglist',
        permission: 'createblog'
      },
      {
        component: CNavItem,
        name: 'صفحات سایت',
        to: '/site/page/pagelist',
        permission: 'createsitepage'
      },
      {
        component: CNavItem,
        name: 'بنرهای سایت',
        to: '/site/banner/bannerlist',
        permission: 'createsitebanner'
      },
      {
        component: CNavItem,
        name: 'قسمت مسئولین',
        to: '/site/director/directorlist',
        permission: 'createdirector'
      }
    ],
  },
  {
    component: CNavItem,
    name: 'پیام ها',
    to: '/messagelist',
    icon: <MailOutlined style={{ fontSize: 25 }} twoToneColor="#eb2f96" />,
    permission: 'viewmessage'
  },
  {
    component: CNavItem,
    name: 'تعریف مدارک',
    to: '/certificate/certificatelist',
    icon: <SafetyCertificateOutlined style={{ fontSize: 25 }} twoToneColor="#eb2f96" />,
    permission: 'viewroles'
  },
  {
    component: CNavItem,
    name: 'دسترسی ها',
    to: '/security/roles',
    icon: <LockOutlined style={{ fontSize: 25 }} twoToneColor="#eb2f96" />,
    permission: 'viewroles'
  },
  // {
  //   component: CNavGroup,
  //   name: 'Buttons',
  //   to: '/buttons',
  //   icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Buttons',
  //       to: '/buttons/buttons',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Buttons groups',
  //       to: '/buttons/button-groups',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Dropdowns',
  //       to: '/buttons/dropdowns',
  //     },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Forms',
  //   icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Form Control',
  //       to: '/forms/form-control',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Select',
  //       to: '/forms/select',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Checks & Radios',
  //       to: '/forms/checks-radios',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Range',
  //       to: '/forms/range',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Input Group',
  //       to: '/forms/input-group',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Floating Labels',
  //       to: '/forms/floating-labels',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Layout',
  //       to: '/forms/layout',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Validation',
  //       to: '/forms/validation',
  //     },
  //   ],
  // },
  // {
  //   component: CNavItem,
  //   name: 'Charts',
  //   to: '/charts',
  //   icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Icons',
  //   icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'CoreUI Free',
  //       to: '/icons/coreui-icons',
  //       badge: {
  //         color: 'success',
  //         text: 'NEW',
  //       },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'CoreUI Flags',
  //       to: '/icons/flags',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'CoreUI Brands',
  //       to: '/icons/brands',
  //     },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Notifications',
  //   icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Alerts',
  //       to: '/notifications/alerts',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Badges',
  //       to: '/notifications/badges',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Modal',
  //       to: '/notifications/modals',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Toasts',
  //       to: '/notifications/toasts',
  //     },
  //   ],
  // },
  // {
  //   component: CNavItem,
  //   name: 'Widgets',
  //   to: '/widgets',
  //   icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  //   // badge: {
  //   //   color: 'info',
  //   //   text: 'NEW',
  //   // },
  // },
  // {
  //   component: CNavTitle,
  //   name: 'Extras',
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Pages',
  //   icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Login',
  //       to: '/login',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Register',
  //       to: '/register',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Error 404',
  //       to: '/404',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Error 500',
  //       to: '/500',
  //     },
  //   ],
  // },
]

export default menuItems
