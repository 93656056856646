import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import Program from "../../components/Program.js";
import { useNavigate } from 'react-router-dom';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
const baseURL = apiUrls.baseURL;

const Trial = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingReport, setLoadingReport] = useState(false);
    const [filter, setFilter] = useState({ skip: 0, limit: 100 });
    const permissions = useSelector((state) => state.permissions.permissions);
    const navigate = useNavigate();
    const { year } = useParams();

    useEffect(() => {
        setLoading(true);
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    useEffect(() => {
        setLoading(true);
        setData([]);
        setFilter({ skip: 0, limit: 100 })
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year])

    const getReport = async () => {
        setLoadingReport(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/program/GetTrialNotifyProgramsReport?year=${year}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }, 'responseType': 'blob'
            });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');

            setLoadingReport(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoadingReport(false)
        } finally {
            setLoadingReport(false)
        }
    }

    const fetchData = async () => {

        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/program/getTrialNotifyPrograms?skip=${filter.skip}&limit=${filter.limit}&year=${year}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            var result = response.data.data;
            setData(result);
            setLoading(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 300
        }}><Spin size="large" /> <HideSidebarInMobileMode /></div>
    }

    return (
        <div>
            {permissions.includes('trialprogramsreport') && <Button onClick={() => getReport()} loading={loadingReport}
                style={{ backgroundColor: 'rgb(28 27 229)', marginTop: 10, width: '100%', marginBottom: 10 }}
                type="primary"><label>گزارش</label></Button>}
            <div className='row'>
                {data?.items?.map((item, index) => {
                    return <Program
                        key={index}
                        programKey='trial'
                        color='rgb(235 225 167)'
                        item={item}
                        //isShowSetPermissionJoin={true}
                        isShowOtherMember={true}
                        isShowRemainingCapacity={true}
                        isHideApproveButton={true}
                        onRefresh={fetchData}>
                    </Program>
                })}
                <Pagination
                    onChange={(pageNumber, limit) => setFilter({ skip: pageNumber - 1, limit: limit })}
                    style={{ marginTop: 30, marginBottom: 5 }}
                    current={filter.skip + 1}
                    responsive={true}
                    pageSize={filter.limit}
                    showTotal={(total) => `تعداد کل: ${total} برنامه`}
                    total={data.count} />
            </div>

        </div>

    )
}

export default Trial
